<template>
  <div class="d-none">
    <b-modal v-model="showModal" centered hide-footer>
      <template #modal-header>
        <div class="modal-title">{{msg}}</div>
      </template>
      <div class="text-center p-5">
        <p class="font-weight-bold text-black">{{ text }}</p>
      </div>
      <div class="d-flex justify-content-between align-items-center w-100">
        <b-button
          variant="primary-color"
          class="btn-modal btn-hover w-50 mr-1"
          @click="confirm"
        >
          ยืนยัน
        </b-button>
        <b-button
          variant="close"
          class="btn-modal btn-hover w-50 ml-1"
          @click="hide('forget')"
        >
          ยกเลิก
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    confirm() {
      this.$emit('confirm');
      this.hide();
    },
    
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.btn-link {
  border-color: transparent !important;
  background-color: transparent !important;
  text-decoration: underline !important;
  padding: 0;
  color: var(--primary-color) !important;
}
</style>
